import React from 'react'
import { Container, Row, Col, Alert, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SectionHeader from './SectionHeader'
import './Game.css'

function Game(props) {

    let mediaObject = {};
    let gamePageObject = {};
    let developmentObject = {};
    const hasGamePage = props.hasGamePage;
    const hasTrailer = props.hasTrailer;
    const hasDevelopment = props.hasDevelopment;

    if (hasTrailer === false) {
        mediaObject = (
            <>
                <h6 className="green-font">Game Logo</h6>
                <Image src={props.src} className="game-item-img" />
            </>
        )
    }
    else {
        mediaObject = (
            <>
                <h6 className="green-font">Trailer</h6>
                <Col className="iframe-container">
                    <iframe
                        src={props.trailer}
                        width="560"
                        height="315"
                        frameborder='0'
                        allow='autoplay; encrypted-media'
                        allowfullscreen
                        title='video'
                    />
                </Col>
            </>
        )
    }

    if (hasGamePage === false) {
        gamePageObject = (<></>)
    }
    else {
        gamePageObject = (
            <Col lg="4">
                <h6 className="green-font">Game Page</h6>
                <Alert.Link href={props.gamePage}>
                    {props.gamePage}
                </Alert.Link>
            </Col>
        )
    }

    if (hasDevelopment === false) {
        developmentObject = (<></>)
    }
    else {
        developmentObject = (
            <Col lg="4">
                <h6 className="green-font">Behind the Development</h6>
                <Link to={props.development}>
                    Development Blog
                </Link>
            </Col>
        )
    }


    return (
        <>
            <Container>
                <SectionHeader text={props.header} />

                <Row>
                    <Col lg="6">
                        {mediaObject}
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <h6 className="green-font">About the Game</h6>
                        {props.about}

                        <h6 className="green-font">Developed By</h6>
                        {props.members}
                    </Col>
                </Row>
                <Row>
                    {gamePageObject}
                    {developmentObject}
                </Row>
            </Container>
        </>
    )
}

export default Game

import React from 'react'
import { Container, Col } from 'react-bootstrap'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function BtdBehemoth() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Behind the Design: Behemoth"
                    date="8 March 2020"
                />
                <Col className="blog-post">
                    <p>Behemoth is a 2D side-scrolling Shoot 'em Up where you control this big ship with firing cannons and protective shields to destroy enemy ships.</p>
                    <p>With this blog post I'll go through the decisions and thoughts behind some major design points in this game. Starting of where it all starts; the start screen.</p>
                    <Col lg="6">
                        <h6 className="green-font">Start Screen</h6>
                        <video controls>
                            <source src="../videos/start-behemoth.mp4" type="video/mp4" />
                        </video>
                    </Col>
                    <p>This is the first time that I used this neat little trick, learning the player the core mechanics from an interactive start menu.</p>
                    <ul>
                        <li>The screen states: "HOLD SPACE"</li>
                        <li>If the player do not follow the command and just tapping a normal shot would fire</li>
                        <li>If the player executes it properly he/she learns how to use the heavy shot</li>
                        <li>Player enters the menu and navigates with 'W' and 'S' for up and down</li>
                        <li>Player press down space to choose an option</li>
                    </ul>
                    <p>With these steps we have shown the player the core mechanics of the game. How to shoot and how to maneauver the cannon.</p>
                    <Col lg="6">
                        <h6 className="green-font">Level Design</h6>
                        <p>I'll go through the level design in seven steps. The video shows you the critical points from gameplay.</p>
                        <video controls>
                            <source src="../videos/level-design-behemoth.mp4" type="video/mp4" />
                        </video>
                        <ol>
                            <li>Starts slow with kamikaze ships that are weak and it's primary objective is to crash into the player. We want the player to have a fairly safe learning environment from the start. To get use to the controls and get a feel for the game</li>
                            <li>Now it spawns more kamikaze ships and we introduce another enemy, shooters, that obviously shoots projectiles</li>
                            <li>The spawner, another new enemy that spawns kamikaze ships. The Spawner has more hit points and serve an acute threat</li>
                            <li>This is one of the peak points in the game. It's actually calculated that the player would get a "perfectly timed" power-up to overcome this danger</li>
                            <li>This part the player can breathe out, no enemies spawning and they can repair themselves. Also the background changes to let the player know that they're getting closer to their objective</li>
                            <li>My favourite part, the boss level. Since we didn't have the time to make a new enemy I came up with the idea that we use what we have; the protective shields. The cannon stops working and the kamikaze ships are sped to increase difficulty</li>
                            <li>After intense boss sequence the player completes the level</li>
                        </ol>
                    </Col>
                    <p>Hope you liked this segment 'behind the design' because there's more coming! If you have any feedback to provide or anything really give me a comment or mail me.</p>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default BtdBehemoth

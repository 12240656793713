import React from 'react'
import { Row, Col, Badge } from 'react-bootstrap'
import './SectionHeader.css'


function SectionHeader(props) {
    return (
        <>
            <Row>
                <Col xs="8">
                    <h2>
                        {props.text}
                    </h2>
                        <Badge pill bg="secondary">
                            {props.date}
                        </Badge>
                </Col>
                <hr />
            </Row>
        </>
    )
}

export default SectionHeader

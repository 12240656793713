import React from 'react'
import './Contact.css'
import { Container, Row, Col, Alert, Button } from 'react-bootstrap'
import SectionHeader from './SectionHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function Contact() {
    return (
        <>
            <Container className="section" id="contact">
                <SectionHeader text="Contact" />

                <Row>
                    <Col >
                        <h6 className="green-font">Resume</h6>
                        <Alert.Link href="./documents/resume.pdf" download="Resume_Joakim-Malmstrom.pdf">
                            <FontAwesomeIcon icon={faFileDownload} className="fa-2x" />
                        </Alert.Link>
                    </Col>
                    <Col>
                        <h6 className="green-font">Mail</h6>
                        <Alert.Link href="mailto: joakim.s.malmstrom@gmail.com">
                            <FontAwesomeIcon icon={faEnvelope} className="fa-2x" />
                        </Alert.Link>
                    </Col>
                    <Col>
                        <h6 className="green-font">LinkedIn</h6>
                        <Alert.Link href="https://www.linkedin.com/in/joakimmalmstrom/">
                            <FontAwesomeIcon icon={faLinkedinIn} className="fa-2x" />
                        </Alert.Link>
                    </Col>
                    <Col>
                        <h6 className="green-font">Github</h6>
                        <Alert.Link href="https://github.com/Joakimmalmstrom">
                            <FontAwesomeIcon icon={faGithub} className="fa-2x" />
                        </Alert.Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Contact

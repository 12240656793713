import React from 'react'
import Game from '../../Game'

function Regrowth() {

    const about = (
        <>
            <p>Regrowth is a mobile app designed to make rehabilitation fun.</p>
            <p>The player completes mini-games to earn flowers and plants to grow in a garden.
                The mini-games are designed for patients with brain damage, and are aimed at improving and training memory, reaction and cognitive abilities. Therefore, to get the full experience out of Regrowth, the developers recommend the game to be used in discussion with doctors and psychiatrists.</p>

        </>
    )

    const members = (
        <>
            <p>Joakim Malmström / Scrum Master & QA</p>
            <p>Eira Saastamoinen / Product Owner & Game Designer</p>
            <p>Clément Pirelli - Game Programmer</p>
            <p>Karl Lindkvist - Game Designer</p>
            <p>Matthias Jannidis - Game Designe</p>
            <p>Lilja Bräckman - Graphical Artist</p>
            <p>Carl Hvarfvenius Blomgren - Graphical Artist</p>
        </>
    )

    return (
        <>
            <Game
                header="Regrowth"
                hasTrailer={false}
                hasDevelopment={false}
                src="../images/Regrowth.png"
                gamePage="https://play.google.com/store/apps/details?id=com.Muninn.Regrowth&hl=sv&gl=US"
                about={about}
                members={members}
            />
        </>
    )
}

export default Regrowth

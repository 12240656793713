import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/NavbarComp';
import Home from './components/pages/Home';
import Blog from './components/pages/Blog';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import HomewardBound from './components/pages/games/HomewardBound';
import Ascenders from './components/pages/games/Ascenders';
import Regrowth from './components/pages/games/Regrowth';
import PaintYourWay from './components/pages/games/PaintYourWay';
import Behemoth from './components/pages/games/Behemoth';
import BtdBehemoth from './components/pages/blogs/BtdBehemoth';
import BtdPaintYourWay from './components/pages/blogs/BtdPaintYourWay';
import BtdAscenders1 from './components/pages/blogs/BtdAscenders1';
import BtdAscenders2 from './components/pages/blogs/BtdAscenders2';
import HomewardBoundReleased from './components/pages/blogs/HomewardBoundReleased';
import BtcLms from './components/pages/blogs/BtcLms';
import WolfPack1 from './components/pages/blogs/WolfPack1';

function App() {
  return (
    <Router>
      <div className="App">
        <NavbarComp />
        <Switch>
          <Route path="/game/homeward-bound" component={HomewardBound} />
          <Route path="/game/ascenders" component={Ascenders} />
          <Route path="/game/regrowth" component={Regrowth} />
          <Route path="/game/paint-your-way" component={PaintYourWay} />
          <Route path="/game/behemoth" component={Behemoth} />
          <Route path="/blog/behemoth" component={BtdBehemoth} />
          <Route path="/blog/paint-your-way" component={BtdPaintYourWay} />
          <Route path="/blog/ascenders-1" component={BtdAscenders1} />
          <Route path="/blog/ascenders-2" component={BtdAscenders2} />
          <Route path="/blog/homeward-bound-released" component={HomewardBoundReleased} />
          <Route path="/blog/lms" component={BtcLms} />
          <Route path="/blog/wolf-pack-1" component={WolfPack1} />
          <Route path="/blog" component={Blog} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

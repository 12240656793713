import React from 'react'
import Game from '../../Game'

function Ascenders() {

    const about = (
        <>
            <p>Adapt to simplistic, but dynamically changed environment and be the first one to reach the Beacon at the top of the tower!</p>
            <ul>
                <li>Generate glass cubes and use them to climb up swiftly</li>
                <li>Shoot blasts to smash opponent’s structures</li>
                <li>Take advantage of the shock waves</li>
                <li>Engage in melee range and knock-back your opponent around</li>
            </ul>
            <p>Ascenders combines and extracts the best from shooter, platformer and race genres. It’s a swift 1v1 race in an enclosed tower with simplistic, but dynamically changed environment.</p>
        </>
    )

    const members = (
        <>
            <p>Joakim Malmström / Product Owner & Designer</p>
            <p>Krzesimir Pszenny - Scrum Master</p>
            <p>Matti Johansson - Game Programmer / Sound</p>
            <p>Marie Collin - Graphical Artist</p>
        </>
    )

    return (
        <>
            <Game
                header="Ascenders"
                trailer="https://www.youtube.com/embed/Wa7jiyX5S0o"
                gamePage="https://offlit.itch.io/ascenders"
                development="/blog/ascenders-1"
                about={about}
                members={members}
            />
        </>
    )
}

export default Ascenders

import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"
import { Container, Row, Col, Table } from 'react-bootstrap'
import SectionHeader from './SectionHeader'

function Competences() {
    return (
        <>
            <Container className="section" id="competences">
                <SectionHeader text="Competences" />

                <Table variant="dark">
                    <thead>
                        <tr className="green-font">
                            <th>Backend</th>
                            <th>Frontend</th>
                            <th>Game Engines</th>
                            <th>Cloud Services</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>C#</td>
                            <td>HTML</td>
                            <td>Unity</td>
                            <td>AWS</td>
                        </tr>
                        <tr>
                            <td>C++</td>
                            <td>CSS</td>
                            <td>Unreal Engine</td>
                            <td>Azure</td>
                        </tr>
                        <tr>
                            <td>WPF</td>
                            <td>JavaScript</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>React</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Angular</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Table variant="dark">
                    <thead>
                        <tr className="green-font">
                            <th>Project Tracking</th>
                            <th>Version Control</th>
                            <th>CI/CD</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Jira</td>
                            <td>Git</td>
                            <td>Jenkins</td>
                        </tr>
                        <tr>
                            <td>Trello</td>
                            <td>Perforce</td>
                            <td>Gitlab</td>
                        </tr>
                        <tr>
                            <td>KanbanFlow</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </>
    )
}

export default Competences

import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import "./NavbarComp.css";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

function NavbarComp() {
    return (
        <div>
            <Navbar bg="dark" expand="lg" variant="dark" >
                <Container>
                    <Navbar.Brand as={Link} to={"/home"} className="nav-name justify-content-end">
                        <h4 className="green-font">Joakim Malmström</h4>
                        <p>Game and System Developer</p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav id="me-auto">
                            <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                            <Nav.Link as={HashLink} to={"/#competences"}>Competences</Nav.Link>
                            <Nav.Link as={Link} to={"/blog"}>Blog</Nav.Link>
                            <Nav.Link as={HashLink} to={"/#about"}>About</Nav.Link>
                            <Nav.Link as={HashLink} to={"/#contact"}>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavbarComp

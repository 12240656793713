import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Col } from 'react-bootstrap'

function PortfolioItem(props) {
    return (
        <>
            <Col className="portfolio-item-wrapper"
                xs={props.xs}
                lg={props.lg}>
                <Link to={props.path}>
                    <Image className="portfolio-item-img" src={props.src} />
                    <Col className="text-wrapper">
                        <h1>{props.text}</h1>
                        <p>{props.info}</p>
                    </Col>
                </Link>
            </Col>
        </>
    )
}

export default PortfolioItem

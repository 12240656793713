import React from 'react'
import { Container, Col, Image, Alert } from 'react-bootstrap'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function WolfPack1() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Project Wolf Pack - Part 1"
                    date="12 April 2021"
                />
                <Col className="blog-post">
                    <p>You're a pack of wolves running through the woods and do what needs to be done in order to survive.
                        You're on the hunt for wildlife and on the lookout for hunters. During two weeks I made a one-page-design, a backlog and some implemented features.
                        This is a hobby project of mine that I did to improve my C++ and game programming.</p>

                    {/* Video */}
                    <h6 className="green-font">Video Documentation</h6>
                    <Col className="iframe-container">
                        <iframe
                            src="https://www.youtube.com/embed/fKwxB_YKEdg"
                            width="560"
                            height="315"
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            allowfullscreen
                            title='video'
                        />
                    </Col>

                    {/* One Page */}
                    <h6 className="green-font">One Page</h6>
                    <Col>
                        <Image src="../images/wolf-pack-blog/wolf-one-page.png" className="blog-item-img" />
                    </Col>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default WolfPack1

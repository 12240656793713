import React from 'react'
import { Container, Col, Image, Alert } from 'react-bootstrap'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function BtcLms() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Behind the Code: Leaning Management System"
                    date="13 December 2020"
                />
                <Col className="blog-post">
                    <p>This fall I started a .NET Developer course at Lexicon. Recently we had a project for a LMS (Learning Management System) which we developed with MVC. I thought it would be a good idea to display some of my work from it.</p>
                    <p><span className="green-font">LMS Github: </span><Alert.Link href="https://github.com/Joakimmalmstrom/LexiconLMS">LMS Repo</Alert.Link></p>

                    {/* Full Teacher View */}
                    <h6 className="green-font">Full Teacher View</h6>
                    <Col>
                        <Image src="../images/lms-blog/full-course.png" className="blog-item-img" />
                    </Col>

                    {/* Course Info */}
                    <h6 className="green-font">Course Info</h6>
                    <Col>
                        <Image src="../images/lms-blog/course-participants.png" className="blog-item-img" />
                    </Col>

                    {/* Current Module */}
                    <h6 className="green-font">Current Module</h6>
                    <Col>
                        <Image src="../images/lms-blog/current-module.png" className="blog-item-img" />
                    </Col>

                    {/* Assignments */}
                    <h6 className="green-font">Assignments</h6>
                    <Col>
                        <Image src="../images/lms-blog/assignments.png" className="blog-item-img" />
                    </Col>

                    {/* Uploaded Assignments */}
                    <h6 className="green-font">Uploaded Assignments </h6>
                    <Col>
                        <Image src="../images/lms-blog/uploaded.png" className="blog-item-img" />
                    </Col>

                    {/* End */}
                    <p>I hope this brought you some clarity in how the functionality works. This is a very small part of the project and I might post more in the coming future.</p>
                    <p>Until then, stay safe!</p>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default BtcLms

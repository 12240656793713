import React from 'react'
import Portfolio from '../Portfolio'
import Competences from '../Competences'
import About from '../About'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"
import Contact from '../Contact'

function Home() {
    return (
        <>
            <Portfolio />
            <Competences />
            <About />
            <Contact />
        </>
    )
}

export default Home

import React from 'react'
import { Container, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function BtdAscenders2() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Behind the Design: Ascenders (2 / 2)"
                    date="28 March 2020"
                />

                <Col className="blog-post">
                    <p>This is the second and final part of Behind the Design: Ascenders. This post is about the actual level of the game, where the two players fight to first reach the beacon! If you haven't checked out the first part about the tutorial you can do it here:</p>
                    <p><span className="green-font">Part 1: </span><Link to="/blog/ascenders-1">Behind the Design: Ascenders (1 / 2)</Link></p>

                    {/* Video */}
                    <h6 className="green-font">Video Commentary</h6>
                    <Col className="iframe-container">
                        <iframe
                            src="https://www.youtube.com/embed/dBdJPHKn-xk"
                            width="560"
                            height="315"
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            allowfullscreen
                            title='video'
                        />
                    </Col>

                    {/* Time Stamps */}
                    <h6 className="green-font">Time Stamps</h6>
                    <p>Time stamps are in the video description.</p>
                    {/* Summary */}
                    <h6 className="green-font">Summary</h6>
                    <p>Thank you for watching the video and I hope you enjoy the format. I try to scale down the text and content in these blog posts as much as I can, cause we only have that much attention span am I right?</p>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default BtdAscenders2

import React from 'react'
import './Portfolio.css';
import { Container, Row } from 'react-bootstrap';
import PortfolioItem from './PortfolioItem'
import SectionHeader from './SectionHeader';

function Portfolio() {
    return (
        <>
            <Container className="section">
                <SectionHeader text="Portfolio" />
                
                <Row>
                    <PortfolioItem
                        xs="12"
                        lg="6"
                        src="./images/Homeward.png"
                        text="Homeward Bound"
                        label="Product Owner & Programmer"
                        path="/game/homeward-bound"
                        info="A CO-OP experience designed for a parent with its kid"
                    />
                    <PortfolioItem
                        xs="12"
                        lg="6"
                        src="./images/Ascenders.png"
                        text="Ascenders"
                        label="Adventure Game"
                        path="/game/ascenders"
                        info="A swift 1v1 game that combines and extracts the best from shooter, platformer and race genres"
                    />
                </Row>
                <Row>
                    <PortfolioItem
                        xs="12"
                        lg="4"
                        src="./images/Regrowth.png"
                        text="Regrowth"
                        label="Adventure Game"
                        path="/game/regrowth"
                        info="Regrowth is a mobile app designed to make rehabilitation fun"
                    />
                    <PortfolioItem
                        xs="12"
                        lg="4"
                        src="./images/PaintYourWay.png"
                        text="Paint Your Way"
                        label="Adventure Game"
                        path="/game/paint-your-way"
                        info="A goofy, racing game with a special designed paint roller as the controller"
                    />
                    <PortfolioItem
                        xs="12"
                        lg="4"
                        src="./images/Behemoth.png"
                        text="Behemoth"
                        label="Adventure Game"
                        path="/game/behemoth"
                        info="Behemoth is a 2D side-scrolling Shoot 'em Up."
                    />
                </Row>
            </Container>
        </>
    )
}

export default Portfolio

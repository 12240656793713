import React from 'react'
import Game from '../../Game'

function HomewardBound() {

    const about = (
        <>
            <p>Homeward Bound is a co-op puzzle platformer where two players are bound together with a rope. Use the rope in various ways to interact with each other and objects in the environment to complete the challenges ahead!</p>
            <p>The key mechanic of the game centers around the rope and how the two players will solve the puzzles with it. </p>
            <p>Homeward Bound is the result of a two month long project, made by game design students. The goal was to develop a game for one of the team member's nephew as a gift. We put our heads together and managed to create this game!</p>
        </>
    )

    const members = (
        <>
            <p>Joakim Malmström / Product Owner & Game Programmer</p>
            <p>Krzesimir Pszenny - Scrum Master</p>
            <p>Matti Johansson - Game Designer / Sound</p>
            <p>Lilja Bräckman - Graphical Artist</p>
        </>
    )

    return (
        <>
            <Game
                header="Homeward Bound"
                trailer="https://youtube.com/embed/fv_h7UUyIO8"
                gamePage="https://mattidev.itch.io/homeward-bound"
                development="/blog/homeward-bound-released"
                about={about}
                members={members}
            />
        </>
    )
}

export default HomewardBound

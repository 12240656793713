import React from 'react'
import { Container, Col, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function HomewardBoundReleased() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Homeward Bound Released"
                    date="22 June 2020"
                />

                <Col className="blog-post">
                    {/* Game Cover*/}
                    <h6 className="green-font">Game Cover</h6>
                    <Row>
                        <Col>
                            <h6>Before</h6>
                        </Col>
                        <Col>
                            <h6>After</h6>
                        </Col>
                    </Row>
                    <Col>
                        <Image src="../images/homeward-bound-blog/ba-game.png" className="blog-item-img" />
                    </Col>

                    {/* Flinging Objects */}
                    <h6 className="green-font">Flinging Objects</h6>
                    <Row>
                        <Col xs="12" lg="6">
                            <h6>Before</h6>
                            <Image src="../images/homeward-bound-blog/before-rope.gif" className="blog-item-img" />
                        </Col>
                        <Col xs="12" lg="6">
                            <h6>After</h6>
                            <Image src="../images/homeward-bound-blog/after-rope.gif" className="blog-item-img" />
                        </Col>
                    </Row>

                    {/* COOP Problem-Solving */}
                    <h6 className="green-font">COOP Problem-Solving</h6>
                    <Row>
                        <Col xs="12" lg="6">
                            <h6>Before - Lever</h6>
                            <Image src="../images/homeward-bound-blog/before-lever.gif" className="blog-item-img" />
                        </Col>
                        <Col xs="12" lg="6">
                            <h6>After - Swinging</h6>
                            <Image src="../images/homeward-bound-blog/after-swinging.gif" className="blog-item-img" />
                        </Col>
                    </Row>

                    {/* Summary */}
                    <h6 className="green-font">Summary</h6>
                    <p>Me and a small team, developed this outside of our studies over a period of two months. I would like to really thank me incredible team for making this small dream of mine come true!</p>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default HomewardBoundReleased

import React from 'react'
import { Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Blog.css'

function BlogItem(props) {
    return (
        <>
            <Col className="blog-item" xs="12" lg={props.lg}>
                <Link className="blog-item-link" to={props.path}>
                    <Col>
                        <h6 className="green-font">{props.title}</h6>
                        <p className="blog-date">{props.date}</p>
                        <Image src={props.src} className="blog-item-img" />
                    </Col>
                    <Col className="blog-item-info">
                        <p className="blog-item-text">{props.text}</p>
                    </Col>
                </Link>
            </Col>
        </>
    )
}

export default BlogItem

import React from 'react'
import { Container, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SectionHeader from '../../SectionHeader'
import '../../Blog.css'

function BtdAscenders1() {
    return (
        <>
            <Container className="section">
                <SectionHeader
                    text="Behind the Design: Ascenders (1 / 2)"
                    date="21 March 2020"
                />

                <Col className="blog-post">
                    <p>This is a two-part series where I'm going to explain the decisions behind the design of Ascenders. In this first part I'm going to be talking about the tutorial's level design.</p>
                    <p><span className="green-font">Part 2: </span><Link to="/blog/ascenders-2">Behind the Design: Ascenders (2 / 2)</Link></p>
                    <h6 className="green-font">Pitch</h6>
                    <p>Ascenders combines and extracts the best from shooter, platformer and race genres. It’s a swift 1v1 race in an enclosed tower with simplistic, but dynamically changed environment.</p>

                    {/* Jumping */}
                    <h6 className="green-font">Jumping</h6>
                    <p>This is the first the player sees when starting the game. I wanted the player to start learning the first essential feature right away; jumping.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-jump.gif" className="blog-item-img" />
                    </Col>
                    <ul>
                        <li>To the right is a box with the jump button visually represented</li>
                        <li>In front of the player is a text with the word  "JUMP"</li>
                        <li>I wanted to make it SUPER clear so that the player would not learn this. I even made the player repeat it after the first obstacle</li>
                    </ul>

                    {/* Building */}
                    <h6 className="green-font">Building</h6>
                    <p>I wanted to limit the player's options where they could build. So that there were no misconceptions where the player would go.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-build.gif" className="blog-item-img" />
                    </Col>
                    <ul>
                        <li>Double communicated; text and control visually represented on a box</li>
                        <li>Requires only one pair of blocks to reach the next platform</li>
                        <li>Forced the player to build instead of trying to jump to the next platform</li>
                    </ul>

                    {/* Shooting */}
                    <h6 className="green-font">Shooting</h6>
                    <p>The yellow cubes would teach the player two things: How to shoot and that cubes are destructable.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-shoot.gif" className="blog-item-img" />
                    </Col>
                    <p>For this section only, I made the player repeat all the new features they've learned in order to progress through the level:</p>
                    <ul>
                        <li>Jumping</li>
                        <li>Building</li>
                        <li>Shooting</li>
                    </ul>

                    {/* Knockback */}
                    <h6 className="green-font">Knockback</h6>
                    <p>When the player reached this section of the level they need to learn their final mechanic; Knockback. The character in front is a copy of the opponent.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-knockback.gif" className="blog-item-img" />
                    </Col>
                    <ul>
                        <li>A bullseye on the wall so that the player would want to hit the center</li>
                        <li>A far drop down to the ground for that 'this is sparta' moment</li>
                        <li>The knockback has small range and here the player could try out just how close they need to be to execute it</li>
                    </ul>

                    {/* Elevator */}
                    <h6 className="green-font">Elevator</h6>
                    <p>The next level has some elevators and we want to introduce the player early on so that they would not be confused.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-elevator.gif" className="blog-item-img" />
                    </Col>
                    <ul>
                        <li>The elevator has a pulsating effect for easy recognition</li>
                        <li>Activates when the player stands on it and resets when they are not</li>
                    </ul>

                    {/* Finale */}
                    <h6 className="green-font">Finale</h6>
                    <p>A longer sequence where the player must build to the top to reach the Beacon which is the winning state. The first player to reach and loads up the Beacon wins.</p>
                    <Col lg="4">
                        <Image src="../images/ascenders-blog/ascenders-finale.gif" className="blog-item-img" />
                    </Col>
                    <ul>
                        <li>An arrow pointing to the Beacon with an extra text</li>
                        <li>A small platform in the middle of the room so the player would not miss it and then sighting the next</li>
                        <li>Strong particle effects around the Beacon to really emphazise it's location and value</li>
                        <li>The load up with the ready screen</li>
                    </ul>

                    {/* Summary */}
                    <h6 className="green-font">Summary</h6>
                    <p>I'm not a huge fan of tutorial levels. I said it, but since we had a limited amount of time on a show floor (Gotland Game Conference), we decided we needed to learn the players in a safe environment before they faced each other. My sole princible for doing the level design of the tutorial was that it needed to be short and tight in its learning.</p>
                    <p>Next week I'll talk about the actual level where the players face each other. That'll be a fun one!</p>

                    <p className="green-font">- Joakim Malmström</p>
                </Col>


            </Container>
        </>
    )
}

export default BtdAscenders1

import React from 'react'
import Game from '../../Game'

function PaintYourWay() {

    const about = (
        <>
            <p>Paint Your Way is a four player, competitive, 2D isometric, goofy, racing game with a special designed paint roller as the controller.</p>
            <p>Each player paints their own track from start to finish. When every player is done or when the time runs out the phase changes from painting to racing.</p>
        </>
    )

    const members = (
        <>
            <p>Joakim Malmström - Product Owner & Game Designer</p>
            <p>Niklas Ericsson - Scrum Master</p>
            <p>Samuel Karabetian - Game Programmer</p>
            <p>Kentaro Hayashida - Game Programmer</p>
            <p>Clara Cox - Graphical Artist</p>
            <p>Marie Collin - Graphical Artist</p>
        </>
    )

    return (
        <>
            <Game
                header="Paint Your Way"
                trailer="https://www.youtube.com/embed/_veL1BJ_hMs"
                hasGamePage={false}
                development="/blog/paint-your-way"
                about={about}
                members={members}
            />
        </>
    )
}

export default PaintYourWay

import React, { Component } from 'react'
import SectionHeader from './SectionHeader.js'
import { Col, Container, Image, Row, Alert, Button } from 'react-bootstrap'
import './About.css'

function About() {
    return (
        <>
            <Container className="section" id="about">
                <SectionHeader text="About" />

                <Row>
                    <Col xs="6" lg="2">
                        <Image id="profile-img" src="./images/profile.jpg"
                            alt="Joakim Malmström"
                            rounded />
                    </Col>
                    <Col xs="6" lg="2">
                        <Row>
                            <h6 className="green-font">Employment</h6>
                            <p>Technical Consultant at YABS</p>
                        </Row>
                        <Row>
                            <h6 className="green-font">Currently Positioned</h6>
                            <p>Developer for Scania RnD</p>
                        </Row>
                        <Row>
                        </Row>
                    </Col>
                    <Col xs="12" lg="8">
                        <h6 className="green-font">Trivia</h6>
                        <p>I'm an involuntarily morning person that's always on time and more often than not drinking coffee. A creative at heart but cannot live without routines and structure.</p>
                        <p>When I'm not programming I like to workout, either lifting weights or practice MMA. After a training session, I love nothing more than having a hot sauna.</p>
                        <h6 className="green-font">Notable Merits</h6>
                        <p>National Swedish Bronze Medalist in MMA & Submission-Wrestling</p>
                        <p>Founder and Trainer for Gotland Submission & MMA</p>
                        <p>Certified MMA Trainer under SMMAF (Swedish MMA Foundation)</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default About;
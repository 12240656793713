import React from 'react'
import { Container, Row } from 'react-bootstrap'
import BlogItem from '../BlogItem'
import SectionHeader from '../SectionHeader'

function Blog() {
    return (
        <>
            <Container className="section">
                <SectionHeader text="Blog" />

                <Row>
                    {/* Wolf Pack 1 */}
                    <BlogItem
                        src="../images/wolf-pack-blog/wolf-pack-blog-thumbnail-1.png"
                        lg="4"
                        title="Project Wolf Pack Part 1"
                        date="12 April 2021"
                        text="You're a pack of wolves running through the woods and do what needs to be done in order to survive..."
                        path="/blog/wolf-pack-1"
                    />
                    {/* LMS */}
                    <BlogItem
                        src="../images/lms-blog/lms-blog-thumbnail.png"
                        lg="4"
                        title="Behind the Code: Learning Management System"
                        date="13 December 2020"
                        text="This fall I started a .NET Developer course at Lexicon. Recently we had a project for a LMS (Learning Management System) which..."
                        path="/blog/lms"
                    />
                    {/* Homeward Bound */}
                    <BlogItem
                        src="../images/homeward-bound-blog/homeward-bound-blog-thumbail.png"
                        lg="4"
                        title="Homeward Bound Released"
                        date="22 June 2020"
                        text="This is the second and final part of Behind the Design: Ascenders. This post is about the actual level of the game, where the..."
                        path="/blog/homeward-bound-released"
                    />
                </Row>
                <Row>
                    {/* Ascenders 2 */}
                    <BlogItem
                        src="../images/ascenders-blog/ascenders-blog-thumbnail-2.png"
                        lg="4"
                        title="Behind the Design: Ascenders Part (2 / 2)"
                        date="28 March 2020"
                        text="This is the second and final part of Behind the Design: Ascenders. This post is about the actual level of the game, where the..."
                        path="/blog/ascenders-2"
                    />
                    {/* Ascenders 1 */}
                    <BlogItem
                        src="../images/ascenders-blog/ascenders-blog-thumbnail-1.png"
                        lg="4"
                        title="Behind the Design: Ascenders Part (1 / 2)"
                        date="21 March 2020"
                        text="This is a two-part series where I'm going to explain the decisions behind the design of Ascenders. In this first part I'm going..."
                        path="/blog/ascenders-1"
                    />
                    {/* Paint Your Way */}
                    <BlogItem
                        src="../images/PaintYourWay.png"
                        lg="4"
                        title="Behind the Design: Paint Your Way"
                        date="14 March 2020"
                        text="Paint Your Way is a four player, competitive, 2D isometric, goofy, racing game with a special designed paint roller..."
                        path="/blog/paint-your-way"
                    />
                </Row>
                <Row>
                    {/* Behemoth */}
                    <BlogItem
                        src="../images/behemoth-start.png"
                        lg="4"
                        title="Behind the Design: Behemoth"
                        date="8 March 2020"
                        text="Behemoth is a 2D side-scrolling Shoot 'em Up where you control this big ship with firing cannons..."
                        path="/blog/behemoth"
                    />
                </Row>
            </Container>
        </>
    )
}

export default Blog

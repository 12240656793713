import React from 'react'
import Game from '../../Game'

function Behemoth() {

    const about = (
        <>
            <p>Behemoth is a 2D side-scrolling Shoot 'em Up.</p>
            <p>The world is at war. You have succesfully infiltrated the enemy lines and stolen a massive prototype ship, you now have to fly it back to your home base!</p>
        </>
    )

    const members = (
        <>
            <p>Joakim Malmström - Product Owner & Game Designer</p>
            <p>Niklas Ericsson - Scrum Master</p>
            <p>Jad El Masri - Game Programmer</p>
            <p>Isac Thorsson - Game Programmer</p>
            <p>Roberto Marcos Söderström - Graphical Artist</p>
            <p>Jesper Karbing - Graphical Artist</p>
        </>
    )

    return (
        <>
            <Game
                header="Behemoth"
                hasTrailer={false}
                src="../images/Behemoth.png"
                gamePage="https://rob-ms.itch.io/behemoth"
                development="/blog/behemoth"
                about={about}
                members={members}
            />
        </>
    )
}

export default Behemoth
